var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('md-button',{staticClass:"md-icon-button md-raised md-round md-info",staticStyle:{"margin":"0.2rem"},on:{"click":function($event){return _vm.showDialog(true)}}},[_c('md-icon',[_vm._v("edit")])],1),_c('md-dialog',{staticClass:"md-layout",attrs:{"md-active":_vm.show,"md-backdrop":true},on:{"update:mdActive":function($event){_vm.show=$event},"update:md-active":function($event){_vm.show=$event}}},[_c('md-dialog-title',[_vm._v("Profile")]),_c('md-dialog-content',{staticClass:"md-layout md-alignment-center-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.load),expression:"load"}],staticClass:"md-layout-item md-size-25"},[_c('md-progress-spinner',{attrs:{"md-mode":"indeterminate"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.load),expression:"!load"}],staticClass:"md-layout md-layout-item md-size-100"},[_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('ValidationObserver',{ref:"form",staticClass:"md-layout md-alignment-center-center"},[_c('form',{staticClass:"md-layout md-layout-item md-size-100 mr-auto",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('signup-card',[_c('div',{staticClass:"md-layout-item md-size-100 mr-auto",attrs:{"slot":"content-right"},slot:"content-right"},[_c('ValidationProvider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("face")]),_c('label',[_vm._v("Nome")]),_c('md-input',{model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("email")]),_c('label',[_vm._v("Email")]),_c('md-input',{model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("phone")]),_c('label',[_vm._v("Fone")]),_c('md-input',{directives:[{name:"mask",rawName:"v-mask",value:('+55 (##) #####-####'),expression:"'+55 (##) #####-####'"}],staticClass:"input",model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Ocupação","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("assignment_ind")]),_c('label',[_vm._v("Ocupação")]),_c('md-select',{attrs:{"name":"occupation","id":"occupation"},model:{value:(_vm.user.occupation),callback:function ($$v) {_vm.$set(_vm.user, "occupation", $$v)},expression:"user.occupation"}},[_c('md-option',{attrs:{"value":"Estudante"}},[_vm._v("Estudante")]),_c('md-option',{attrs:{"value":"Profissional de Saúde"}},[_vm._v("Profissional de Saúde")]),_c('md-option',{attrs:{"value":"Gestor"}},[_vm._v("Gestor")]),_c('md-option',{attrs:{"value":"Esquisador"}},[_vm._v("Esquisador")])],1),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1)])],1)])],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-field',[_c('div',{staticClass:"md-layout"},_vm._l((_vm.roles),function(role){return _c('md-switch',{key:role.id,staticClass:"md-primary md-layout-item md-size-30 md-small-size-100",model:{value:(role.selected),callback:function ($$v) {_vm.$set(role, "selected", $$v)},expression:"role.selected"}},[_vm._v(" "+_vm._s(role.alias)+" ")])}),1)]),_c('div',{staticClass:"button-container"},[_c('md-button',{staticClass:"md-indigo md-round mt-4",on:{"click":_vm.resetPassword}},[_vm._v(" Resetar senha ")])],1)],1)])]),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-primary",on:{"click":function($event){return _vm.showDialog(false)}}},[_vm._v("Cancelar")]),_c('md-button',{staticClass:"md-primary",on:{"click":_vm.onSubmit}},[_vm._v("Salvar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }