<template>
  <div>
    <md-button
      class="md-icon-button md-raised md-round md-info"
      @click="showDialog(true)"
      style="margin: 0.2rem"
    >
      <md-icon>edit</md-icon>
    </md-button>

    <md-dialog :md-active.sync="show" :md-backdrop="true" class="md-layout">
      <md-dialog-title>Profile</md-dialog-title>
      <md-dialog-content class="md-layout md-alignment-center-center">
        <div class="md-layout-item md-size-25" v-show="load">
          <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div class="md-layout md-layout-item md-size-100" v-show="!load">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <ValidationObserver
              ref="form"
              class="md-layout md-alignment-center-center"
            >
              <form
                @submit.prevent="onSubmit"
                class="md-layout md-layout-item md-size-100 mr-auto"
              >
                <signup-card>
                  <div
                    class="md-layout-item md-size-100 mr-auto"
                    slot="content-right"
                  >
                    <ValidationProvider
                      name="nome"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                      >
                        <md-icon>face</md-icon>
                        <label>Nome</label>

                        <md-input v-model="user.name" />
                        <validation-error :errors="errors" />
                      </md-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="e-mail"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                      >
                        <md-icon>email</md-icon>
                        <label>Email</label>

                        <md-input v-model="user.email" />
                        <validation-error :errors="errors" />
                      </md-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="telefone"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                      >
                        <md-icon>phone</md-icon>
                        <label>Fone</label>
                        <md-input
                          v-model="user.phone"
                          class="input"
                          v-mask="'+55 (##) #####-####'"
                        />
                        <validation-error :errors="errors" />
                      </md-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Ocupação"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                      >
                        <md-icon>assignment_ind</md-icon>
                        <label>Ocupação</label>
                        <md-select
                          v-model="user.occupation"
                          name="occupation"
                          id="occupation"
                        >
                          <md-option value="Estudante">Estudante</md-option>
                          <md-option value="Profissional de Saúde"
                            >Profissional de Saúde</md-option
                          >
                          <md-option value="Gestor">Gestor</md-option>
                          <md-option value="Esquisador">Esquisador</md-option>
                        </md-select>
                        <validation-error :errors="errors" />
                      </md-field>
                    </ValidationProvider>
                  </div>
                </signup-card>
              </form>
            </ValidationObserver>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <md-field>
              <div class="md-layout">
                <md-switch
                  v-for="role in roles"
                  :key="role.id"
                  v-model="role.selected"
                  class="md-primary md-layout-item md-size-30 md-small-size-100"
                >
                  {{ role.alias }}
                </md-switch>
              </div>
            </md-field>
            <div class="button-container">
              <md-button class="md-indigo md-round mt-4" @click="resetPassword">
                Resetar senha
              </md-button>
            </div>
          </div>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog(false)"
          >Cancelar</md-button
        >
        <md-button class="md-primary" @click="onSubmit">Salvar</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { SignupCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";

export default {
  components: {
    SignupCard,
    ValidationError
  },
  mixins: [formMixin],
  props: {
    user: Object,
    roles: Array
  },
  data() {
    return {
      show: false,
      load: false,
      selectedRoles: []
    };
  },
  created() {},
  methods: {
    showDialog(show) {
      this.roles.forEach(role => {
        role.selected = false;
      });

      if (show) {
        this.user.roles.forEach(userRole => {
          this.roles.forEach(role => {
            if (userRole.id == role.id) {
              role.selected = true;
            }
          });
        });
      }
      this.show = show;
      return show;
    },
    async resetPassword() {
      this.load = true;
      try {
        await Vue.$http.post(`v1/forgot-password`, {
          email: this.user.email
        });
        await this.$store.dispatch(
          "alerts/success",
          "Verifique sua caixa de email."
        );
        this.load = false;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e.response.data.message);
        this.load = false;
      }
    },
    async update() {
      try {
        await Vue.$http.put(`v1/user/${this.user.id}`, this.user);
        await this.$store.dispatch(
          "alerts/success",
          "Profile updated successfully."
        );
        this.load = false;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e.response.data.message);
        this.load = false;
      }
    },
    async onSubmit() {
      this.load = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          let values = Object.values(this.$refs.form.errors);
          values.forEach(value => {
            value.forEach(message => {
              this.alert(message);
            });
          });
          this.load = false;
          return;
        }

        this.selectedRoles = [];
        this.roles.forEach(role => {
          if (role.selected) {
            this.selectedRoles.push(role);
          }
        });
        this.user.roles = this.selectedRoles;

        this.update();
        this.$nextTick(() => {
          //this.$refs.form.reset();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.md-dialog .md-dialog-container {
  width: 60% !important;
}
</style>
